<template>
  <div class="container-top" v-if="value">
    <div class="pc container">
      <div class="text-center margin-top-80">
        <i class="material-icons primary" style="font-size:53px">check_circle_outline</i>
        <div class="h4 main margin-top-28">주문제작 견적 신청이 완료되었습니다.</div>
        <div class="body2 sub2 margin-top-12">견적신청이 확인되면 상세한 서비스 파악을 위해  담당자가 유선 상담을 진행합니다.</div>
      </div>

      <div class="h7 main" style="margin-top:96px">서비스 정보</div>
      <div class="lp-divider-sub5 margin-top-16"></div>
      <div class="flex-align margin-top-16">
        <div class="inquiry-reg-title subtitle5 main">서비스 이름</div>
        <div class="inquiry-reg-content body2 sub">{{ value.service_name }}</div>
      </div>
      <div class="flex-align margin-top-16">
        <div class="inquiry-reg-title subtitle5 main">제작예산</div>
        <div class="inquiry-reg-content body2 sub">{{ value.min_price }} ~ {{ value.max_price }}</div>
      </div>
      <div class="flex-align margin-top-16">
        <div class="inquiry-reg-title subtitle5 main">희망 소요기간</div>
        <div class="inquiry-reg-content body2 sub">{{ value.hope_date }}</div>
      </div>
      <div class="flex-align margin-top-16">
        <div class="inquiry-reg-title subtitle5 main">서비스 지원환경</div>
        <div class="inquiry-reg-content body2 sub">{{ value.support }}</div>
      </div>
      <div class="h7 main" style="padding-top:96px">개설자 정보</div>
      <div class="lp-divider-sub5 margin-top-16"></div>
      <div class="flex-align margin-top-16">
        <div class="inquiry-reg-title subtitle5 main">신청자</div>
        <div class="inquiry-reg-content body2 sub">{{ value.name }}</div>
      </div>
      <div class="flex-align margin-top-16">
        <div class="inquiry-reg-title subtitle5 main">연락처</div>
        <div class="inquiry-reg-content body2 sub">{{ value.phone }}</div>
      </div>
      <div class="flex-align margin-top-16">
        <div class="inquiry-reg-title subtitle5 main">이메일</div>
        <div class="inquiry-reg-content body2 sub">{{ value.email }}</div>
      </div>

      <div class="flex-center margin-top-80">
        <button class="button is-gray body2-medium"
                style="width:242px;height:48px"
                @click="routerPush('/home')">홈으로 돌아가기</button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "ServiceInquiryDone",
    created() {
      this.getData();
    },
    data() {
      return {
        value: undefined,
      }
    },
    methods: {
      getData() {
        this.$axios.get(`public/launchpack/inquiry/${this.$route.query.id}`).then(res=>{
          this.value = res.data;
        })
      }
    }
  }
</script>
<style lang="stylus" scoped>
  .pc .inquiry-reg-title
    width 30%
  .pc .inquiry-reg-content body2
    width 70%
</style>
